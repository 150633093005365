import React, { useState, useEffect, useCallback } from 'react';
import {
  getFirebaseFirestore,
  getFirebaseStorage,
} from '@babelcoder/gatsby-plugin-firebase';

import { useGlobalState } from '@babelcoder/gatsby-theme-base/src/components/GlobalStateProvider';
import AuthGuard from '@babelcoder/gatsby-theme-base/src/components/AuthGuard';
import Courses from '../../components/dashboard/Courses';

function CoursesPage() {
  const { user } = useGlobalState();
  const [isLoading, setIsLoading] = useState(true);
  const [courses, setCourses] = useState([]);

  const loadCourses = useCallback(async () => {
    if (!user) return;

    try {
      const firestore = await getFirebaseFirestore();
      const firebaseStorage = await getFirebaseStorage();
      const subscriptionsSnapshot = await firestore
        .collection(`users/${user.uid}/subscriptions`)
        .get();
      const ids = subscriptionsSnapshot.docs.map((doc) => doc.id);
      const coursesSnapshot = await firestore
        .collection('courses')
        .where('slug', 'in', ids)
        .limit(10)
        .get();
      const courses = await Promise.all(
        coursesSnapshot.docs.map(async (doc) => {
          const { image, ...data } = doc.data();

          const imageURL = await firebaseStorage
            .refFromURL(image)
            .getDownloadURL();

          return { ...data, image: imageURL };
        })
      );

      setCourses(courses);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    loadCourses();
  }, [loadCourses]);

  return (
    <AuthGuard emailMustVerified={true}>
      <Courses isLoading={isLoading} courses={courses}></Courses>
    </AuthGuard>
  );
}

export default CoursesPage;
