import React from 'react'
import styled, { css } from 'styled-components'

import useCoursesPath from '@babelcoder/gatsby-theme-courses/src/hooks/useCoursesPath'
import LoadingImage from '@babelcoder/gatsby-theme-base/assets/images/loading.svg'
import SectionList from '../home/SectionList'
import SectionItem from '../home/SectionItem'

const Wrapper = styled.section`
  max-width: 1100px;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacers.normal};
`

const Title = styled.h2`
  ${({ theme }) => css`
    position: relative;
    margin-bottom: ${theme.spacers.normal};
    color: ${theme.colors.neutral.gray1200};
    font-size: ${theme.fontSizes.large};
    text-align: center;

    &::before {
      position: absolute;
      content: '';
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 30px;
      height: 3px;
      background-color: ${theme.colors.main.primary};
    }
  `}
`

function Courses({ isLoading, courses }) {
  const { getCourseOverviewPath } = useCoursesPath()

  return (
    <Wrapper>
      <Title>คอร์สของฉัน</Title>
      {isLoading ? (
        <LoadingImage></LoadingImage>
      ) : courses.length > 0 ? (
        <SectionList>
          {courses.map(({ slug, title, excerpt, image }) => (
            <SectionItem
              key={slug}
              to={getCourseOverviewPath(slug)}
              image={image}
              imageType="url"
              imageAlt={title}
              title={title}
              excerpt={excerpt}
            ></SectionItem>
          ))}
        </SectionList>
      ) : (
        <p>ไม่พบคอร์สที่คุณได้ลงทะเบียนไว้</p>
      )}
    </Wrapper>
  )
}

export default Courses
